@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&family=Tajawal:wght@200;300;500;700&display=swap');
.App {
  background-color: #ECF2FF
}
.App,.college{
  font-family: 'Tajawal';
  
}
.docs{
  box-sizing: 'border-box' ;
}